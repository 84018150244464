@import url("./assets/lib/normalize.css");

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora/Lora-VariableFont_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lora;
  src: url("./assets/fonts/Lora/Lora-Italic-VariableFont_wght.ttf");
  font-style: italic;
  font-display: swap;
}

* {
  font-family: Lora;
}

html {
  scroll-behavior: smooth;
  font-size: 17px;
}

@media screen and (max-width: 1320px) {
  html {
    font-size: 15px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 14px;
  }
}

#root {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h2 {
  font-size: 1.87rem;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.widthStop {
  max-width: 1440px;
  min-width: 320px;
  margin: 0 auto;
}

.generalMargin {
  margin-right: 150px;
  margin-left: 150px;
}

.marginTop {
  margin-top: 100px;
}

.borders {
  padding: 15px;
  border: 1px solid;
}

.inputErrorText {
  font-size: 14px;
  color: #e64343;
}

.inputSuccessText {
  font-size: 14px;
  color: #39b517;
}

@media screen and (max-width: 1300px) {
  .generalMargin {
    margin-right: 80px;
    margin-left: 80px;
  }
}

@media screen and (max-width: 950px) {
  .generalMargin {
    margin-right: 40px;
    margin-left: 40px;
  }
}

@media screen and (max-width: 768px) {
  .generalMargin {
    margin-right: 20px;
    margin-left: 20px;
  }

  .borders {
    padding: 8px;
  }
}
