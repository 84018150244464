.parallax1 {
  height: 100vh;
  width: 100%;
  z-index: 10;
  margin-top: 100px;

  position: relative;
  z-index: -1;
}

.parallax1__wrap {
  width: 100%;
  height: 100%;

  background: url("../../assets/images/1_46 - Фото.e5801ba1fd9c52f77cde_4_4.webp");
  background-size: cover;
  background-position: center;
}
