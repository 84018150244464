.parallax2 {
  height: 100vh;
  width: 100%;
  z-index: 10;
  margin-top: 100px;

  position: relative;
  z-index: -1;
}

.parallax2__wrap {
  width: 100%;
  height: 100%;

  background: url("../../assets/images/1_54 - Фото.d074341c30d22caf7325_7_7.webp");
  background-size: cover;
  background-position: center;
}
