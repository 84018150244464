.advantages {
}

.advantages__title {
  font-size: 1.87rem;
  text-align: center;
  margin-bottom: 80px;
}

.advantages__list {
  margin: 0;
  margin-left: 100px;
  margin-bottom: 10px;
  padding: 0;
  list-style: circle;

  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 160px;
  grid-auto-flow: column;
  justify-content: space-between;

  max-width: 950px;

  counter-reset: item;
  list-style-type: none;

  margin: 0 8%;
}

.advantages__item {
  max-width: 350px;
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 50px;
}

.advantages__item:before {
  content: counter(item) " ";
  counter-increment: item;
  font-size: 3.12rem;
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translate(0, -50%);
  color: #e58b47;
}

.advantages__photos {
  display: flex;
}

.advantages__photo:first-child {
  margin-right: 10px;
}

.advantages__photo:last-child {
  margin-left: 10px;
}

.advantages__images {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .advantages__list {
    column-gap: 130px;
    margin: 0 13%;
  }

  .advantages__item {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .advantages__title {
    margin-bottom: 40px;
  }

  .advantages__list {
    grid-template-columns: 1fr;
    grid-auto-flow: row;

    margin: 0 auto;
    max-width: 70%;
    column-gap: 74px;
  }

  .advantages__item {
    margin-bottom: 50px;
    justify-content: center;
    text-align: center;
    font-size: 1.2em;
  }

  .advantages__item::before {
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    font-size: 4.12rem;
  }

  .advantages__photos {
    flex-direction: column;
  }

  .advantages__photos .advantages__photo {
    margin: 0;
  }

  .advantages__photos .advantages__photo:first-child {
    margin-bottom: 20px;
  }
}
