.parallax3 {
  height: 100vh;
  width: 100%;
  margin-top: 100px;

  position: relative;
  z-index: -1;
}

.parallax3__wrap {
  width: 100%;
  height: 100%;

  background: url("../../assets/images/1_45 - Фото.2624f0c01bb128150fee_3_3.webp");
  background-size: cover;
  background-position: center;
}
