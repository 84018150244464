.mainScreen {
  background: url("../../assets/images/1_51 - Фото.b598ba5829bc29990d8f_6_6.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: white;

  height: 100vh;
  min-height: 700px;
  max-height: 780px;

  width: 100%;
  display: flex;
  justify-content: center;

  position: relative;
  z-index: 10;
}

.mainScreen::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;
  height: 100%;
  background: rgb(56, 56, 56);
  background: linear-gradient(
    180deg,
    rgba(56, 56, 56, 0.702140231092437) 0%,
    rgba(56, 56, 56, 0.3996192226890757) 100%
  );
}

.mainScreen__wrap {
  width: 100%;
}

/* 

  mainScreen__content
  
*/

.mainScreen__content {
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-top: 15vh;
}

.mainScreen__contentWrap {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

/* 

  mainCallback 

*/

.mainCallback {
  max-width: 400px;
  width: 100%;

  background: #fff;
  color: black;

  margin-right: 70px;
  padding: 30px 40px;
  width: 100%;

  height: fit-content;
}

.mainCallback__title {
  margin-top: 5px;
  margin-bottom: 30px;
  font-size: 1.56rem;
}

.mainAdvantages {
  margin-bottom: 30px;
}

.mainAdvantages__title {
  margin-bottom: 40px;
}

.mainAdvantages__title_mark {
  background: none;
  color: #ffda92;
}

.mainAdvantages__list {
  list-style: none;
  margin-left: 30px;
}

.mainAdvantages__item {
  margin-bottom: 30px;
  font-size: 20px;
  position: relative;
  max-width: 350px;
  min-width: 230px;
}

.mainAdvantages__item::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30%;
  left: -60px;
  transform: translateY(-50%);
}

.mainAdvantages__item:first-child:after {
  background: url("../../assets/svg/plump.svg");
}

.mainAdvantages__item:nth-child(2):after {
  background: url("../../assets/svg/house-by-the-sea.svg");
}

.mainAdvantages__item:last-child:after {
  background: url("../../assets/svg/houses.svg");
}

.mainCallback__inputSection {
  margin-bottom: 20px;
}

.mainCallback__inputLabel {
  margin-bottom: 10px;
  display: block;
}

.mainCallback__inputs .mainCallback__input input {
  width: 100%;
}

.mainCallback__submitBtn {
  width: 100%;

  outline: none;
  border: none;

  background: #e2711d;
  color: white;

  padding-top: 15px;
  padding-bottom: 15px;

  cursor: pointer;

  margin-bottom: 10px;
}

.mainCallback__submitBtn:hover {
  background: #c7651a;
}

.mainCallback__submitDescrip {
  font-size: 0.75rem;
  margin: 0;
  color: #00000040;
}

.mainAdvantages__title {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .mainScreen__content {
    margin-top: 40px;
  }

  .mainScreen__contentWrap {
    flex-direction: column-reverse;
    align-items: center;
  }

  .mainAdvantages {
    margin-left: 10px;
    width: auto;
  }

  .mainAdvantages__list {
    margin-left: 20px;
  }

  .mainCallback {
    margin-right: 0;
  }
}

@media screen and (max-width: 520px) {
  .mainScreen__content {
    margin-top: 20px;
  }

  .mainAdvantages {
    margin-bottom: 0;
    margin-left: 0;
  }

  .mainAdvantages__title {
    font-size: 1.5em;
  }

  .mainCallback {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .mainCallback__title {
    font-size: 1.35rem;
  }
}

@media screen and (max-width: 400px) {
  .mainScreen {
    height: auto;
    padding-bottom: 40px;
  }

  .mainScreen__contentWrap {
    margin: 0 20px;
  }

  .mainAdvantages__list {
    margin-left: 30px;
  }

  .mainAdvantages__item {
    min-width: auto;
  }
}
