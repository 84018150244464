.callbackModal {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 20;

  background: #00000050;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.callbackModal_close {
  display: none;
}

.callbackModal__wrap {
  display: flex;
  flex-direction: column;

  background: #fff;

  padding: 50px;

  max-width: 500px;

  margin: 0 30px;

  position: relative;
}

.callbackModal__wrap > .toggle-menu {
  width: 30px;
  height: 45px;
  display: inline-block;

  position: absolute;
  top: 15px;
  right: 30px;
  z-index: 999;

  cursor: pointer;
}

.callbackModal__wrap > .toggle-menu i {
  position: absolute;
  display: block;
  height: 2px;
  background: #fff;
  width: 30px;
  left: 0px;
  transition: all 0.3s;
}

.callbackModal__wrap .toggle-menu i {
  background: #000;
}

.callbackModal__wrap > .toggle-menu.active i:nth-child(1) {
  top: 25px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.callbackModal__wrap > .toggle-menu.active i:nth-child(2) {
  background: transparent;
}

.callbackModal__wrap > .toggle-menu.active i:nth-child(3) {
  top: 25px;
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.callbackModal__title {
  font-size: 1.56rem;
  margin-top: 0;
  margin-bottom: 15px;
  margin-right: 45px;
}

.callbackModal__descrip {
  color: #00000040;
  font-size: 0.87rem;
  line-height: 20px;
}

.callbackModal__input {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 5px 15px;
  outline: none;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.callbackModal__inputTel {
  margin-bottom: 20px;
}

.callbackModal .callbackModal__wrap .callbackModal__inputTel button,
.callbackModal .callbackModal__wrap .callbackModal__inputTel input {
  border: none;
}

.callbackModal .callbackModal__wrap .callbackModal__inputTel input {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .callbackModal__wrap {
    padding: 30px;
  }
}
