.planing__titleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planing__title {
  text-align: center;
}

.planing__subtitle {
  text-align: center;
  margin-bottom: 100px;
  font-size: 1.25rem;
  max-width: 500px;
  line-height: 150%;
}

.planing__item {
  margin-bottom: 135px;
}

@media screen and (max-width: 768px) {
  .planing__title {
    margin-bottom: 0;
  }

  .planing__item {
    margin-bottom: 0px;
  }
}
