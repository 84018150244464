.footer {
  background: #dce6ff;
  display: flex;
  justify-content: center;
}

.footer__wrap {
  padding: 60px 0 200px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer__background {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer__info {
  display: grid;
  grid-template-columns: 240px 330px;
  grid-template-rows: 1fr 1fr;
}

.footer__listWrap {
  margin-right: 30px;
  margin-bottom: 20px;
}

.footer__listWrap:last-child {
  margin-right: 0;
}

.footer__listTitle {
  font-weight: 500;
}

.footer__listWrap::after {
  margin-right: 0;
}

.footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 10px;
}

.footer__listItem {
  margin-bottom: 5px;
  line-height: 140%;
}

.footer__companyName {
  font-size: 1.37rem;
}

@media screen and (max-width: 850px) {
  .footer__info {
    grid-template-columns: 270px;
  }

  .footer__list {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .footer__background {
    flex-direction: column-reverse;
  }

  .footer__companyName {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 480px) {
  .footer__background {
    margin-right: 20px;
    margin-left: 20px;
  }

  .footer__info {
    flex-direction: column;
  }

  .footer__listWrap {
    margin-right: 0;
  }
}
