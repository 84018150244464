.callback {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  padding: 35px 0;
  position: relative;
}

.callback__info {
  max-width: 420px;
}

.callback__title {
  font-size: 1.56rem;
  margin-bottom: 15px;
}

.callback__form {
  max-width: 330px;
  width: 100%;
}

.callback__form_success {
  font-size: 14px;
  text-align: center;
  max-width: 200px;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  color: green;

  transform: translateX(-50%);
}

.callback__descrip {
  color: #000000cf;
  font-size: 0.87rem;
  line-height: 20px;
}

.callback__input {
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: 5px 15px;
  outline: none;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.callback__inputTel {
  margin-bottom: 20px;
}

.callback .callback__inputTel button,
.callback .callback__inputTel input {
  border: none;
}

@media screen and (max-width: 950px) {
  .callback {
    flex-direction: column;
    align-items: center;

    padding: 35px;
  }

  .callback__title {
    text-align: center;
  }

  .callback__descrip {
    text-align: center;
  }

  .callback__info,
  .callback__form {
    max-width: 420px;
  }

  .callback__info {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .callback.borders {
    padding: 15px 35px;
    text-align: center;
  }
}
